<script setup>
import NavigationMenu from './Header/NavigationMenu.vue';
import MobileSlideMenu from './Header/MobileSlideMenu.vue';
import { ref } from 'vue';

const showResponsiveMenu = ref(false);

const handleResponsiveMenu = (arg) => {
    showResponsiveMenu.value = arg;
    if (arg) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }
};

const logoLink = '/';
</script>

<template>
    <!-- Main Navigation -->
    <NavigationMenu
        :logo-link="logoLink"
        :showResponsiveMenu="showResponsiveMenu"
        @handleResponsiveMenu="(event) => handleResponsiveMenu(event)"
    />

    <!-- Responsive Navigation Menu -->
    <MobileSlideMenu
        :logo-link="logoLink"
        @handleResponsiveMenu="(event) => handleResponsiveMenu(event)"
        :showResponsiveMenu="showResponsiveMenu"
    />
</template>
