<script setup>
import InputError from '@/customer/Components/InputError.vue';
import Button from '@/customer/Components/BaseButton.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import Checkbox from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import PhoneInput from '@/customer/Components/UI/Input/MaskedInputs/PhoneInput.vue';
import GenericMaskedInput from '@/customer/Components/UI/Input/MaskedInputs/GenericMaskedInput.vue';
import { ref } from 'vue';
import InputPassword from '@/customer/Components/InputPassword.vue';

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    birthday: '',
    phone: '',
    notifications: false,
    tos: false,
});

const reactivated = ref(false);

const submit = () => {
    form.post(route('customer.register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};

const requestReactivation = () => {
    form.post(route('customer.request_reactivate'), {
        onFinish: () => {
            form.reset('password', 'password_confirmation');
            reactivated.value = true;
        },
    });
};
</script>

<template>

    <Head title="Register" />

    <div class="my-8 md:my-0">
        <div v-if="!reactivated" class="text-center">
            <h2 class="font-sans text-2xl font-bold uppercase">Register</h2>
            <span class="text-xl">Let's set up your account!</span>
        </div>

        <form v-if="!reactivated" class="m-auto mt-6 px-2 sm:px-10 md:mt-12 md:px-24 lg:px-40 xl:w-1/2 xl:px-0"
            @submit.prevent="submit">
            <div class="grid gap-4 md:grid-cols-2 md:gap-8">
                <div>
                    <InputGroup id="first_name" v-model="form.first_name" name="first_name"
                        :error-message="form.errors.first_name" type="text" class="mt-1 block w-full" autofocus
                        autocomplete="first_name" label="First Name" />
                </div>

                <div>
                    <InputGroup id="last_name" v-model="form.last_name" name="last_name" type="text"
                        class="mt-1 block w-full" autocomplete="family-name" label="Last Name"
                        :error-message="form.errors.last_name" />
                </div>
            </div>

            <div class="mt-4">
                <InputGroup id="email" name="email" v-model="form.email" class="mt-1 block w-full" autocomplete="email"
                    label="Email Address" :error-message="form.errors.email" />
                <div class="mt-1 block w-full" v-if="
                        form.errors.email ==
                        'This email address has been deactivated. Would you like to send an email link to reactivate this account?'
                    ">
                    <Button class="ml-2" @click="requestReactivation()">Reactivate</Button>
                </div>
            </div>

            <div class="mt-4">
                <InputPassword id="password" label="Password" v-model="form.password" class="mt-1 block w-full"
                    autocomplete="new-password" :error-message="form.errors.password" />
            </div>

            <div class="mt-4">
                <InputPassword id="password_confirmation" name="password_confirmation"
                    v-model="form.password_confirmation" class="mt-1 block w-full" autocomplete="new-password"
                    label="Retype Password" :confirm-error-message="form.errors.password_confirmation" />
            </div>

            <div class="mt-4">
                <InputGroup id="phone" name="phone" v-model="form.phone" :type="PhoneInput" class="mt-1 block w-full"
                    autocomplete="phone" label="Phone Number" :error-message="form.errors.phone" />
            </div>

            <div class="mt-4">
                <InputGroup id="birthday" v-model="form.birthday" :type="GenericMaskedInput" label="Birthday (Optional)"
                    class="mt-1 block w-full" autocomplete="birthday" placeholder="MM/DD"
                    :error-message="form.errors.birthday" mask="mM/dD" :tokens="{
                        m: { pattern: /[0-1]/ },
                        M: { pattern: /[0-9]/ },
                        d: { pattern: /[0-3]/ },
                        D: { pattern: /[0-9]/ },
                    }" />
            </div>

            <div class="mt-8 block">
                <Checkbox v-model="form.notifications" name="notifications">
                    <template #label>
                        <span class="ml-2 text-sm text-gray-600">
                            Let me know about specials and upcoming events.
                        </span>
                    </template>
                </Checkbox>
                <InputError class="mt-2" :message="form.errors.notifications" />
            </div>

            <div class="mt-4 block">
                <Checkbox v-model="form.tos" name="tos">
                    <template #label>
                        <span class="ml-2 text-sm text-gray-600">
                            *I have read and understand the
                            <a
                                href="https://palmersdeliandmarket.com/privacy-policy/"
                                class="text-palmers-dragon-fruit decoration-palmers-dragon-fruit supports-hover:hover:underline"
                                target="__blank">
                                Privacy Policy
                            </a>
                        </span>
                    </template>
                </Checkbox>
                <InputError class="mt-2" :message="form.errors.tos" />
            </div>

            <div class="mt-6 flex items-center justify-end">
                <Link :href="route('customer.login')"
                    class="rounded-md text-sm font-semibold text-neutral-700 underline focus:outline-none focus:ring-2 focus:ring-palmers-dragon-fruit focus:ring-offset-2 supports-hover:hover:text-neutral-900">
                Already registered?
                </Link>
                <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Create account
                </Button>
            </div>
        </form>
        <div v-else class="text-center">
            <h2 class="mb-2 font-sans text-2xl font-bold uppercase">Check your inbox!</h2>
            <p>
                An account reactivation link was sent to
                <span class="font-semibold">{{ form.email }}</span>
            </p>
        </div>
    </div>
</template>
