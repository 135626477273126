<script setup>
import { CheckboxInput, InputGroup, Toggle } from '@/customer/Components';
import { useAuthNetStore, useCheckoutStore } from '@/customer/Composables';
import PhoneInput from '@/customer/Components/UI/Input/MaskedInputs/PhoneInput.vue';
import { ref, watch, computed } from 'vue';

import { string } from 'yup';
import InputLabel from '@/company/Components/InputLabel.vue';
import { usePage } from '@inertiajs/vue3';
import CheckoutSelect from '@/customer/Components/UI/Input/Select/CheckoutSelect.vue';

const authnet = useAuthNetStore();
const checkout = useCheckoutStore();
const page = usePage();

const userName = page.props.auth.user.name;
const userEmail = page.props.auth.user.email;
const userPhones = page.props.userPhoneNumbers;
const userPhoneOptions = userPhones.map((num) => {
    return {
        id: num.id,
        label: `${num.label && num.label != 'Phone' ? `${num.label} - ` : ''}${num.number}`,
        number: num.number,
    };
});

const useAccountInfo = ref(false);

watch(
    () => useAccountInfo.value,
    (newVal) => {
        if (newVal) {
            checkout.nameForOrder = userName;
            authnet.responseData.contactDetails.email = userEmail;
            authnet.responseData.contactDetails.phone = userPhones.length
                ? userPhones[0].number
                : '';
        }
    },
);

const setPhone = (e) => {
    const selectedNumber = !e ? '' : userPhones.find((phone) => phone.id == Number(e)).number;
    authnet.responseData.contactDetails.phone = selectedNumber;
};

const phoneSelectValue = computed(() =>
    authnet.responseData.contactDetails.phone == ''
        ? null
        : authnet.responseData.contactDetails.phone,
);
</script>
<template>
    <div class="space-y-2">
        <div class="flex flex-wrap items-center justify-between gap-x-4">
            <div class="text-xl font-bold text-palmers-charcoal-grey">Contact Information</div>
            <div class="flex items-center gap-x-2">
                <InputLabel>Use Account Info</InputLabel>
                <Toggle
                    :value="useAccountInfo"
                    sr-label="Use account info for order contact"
                    @toggle="(e) => (useAccountInfo = e)"
                />
            </div>
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-3">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="Customer Name"
                name="nameForOrder"
                v-model="checkout.nameForOrder"
                :validate="checkout.failed"
                :validator="string().required()"
                :validator-attributes="['nameForOrder']"
                input-class="disabled:bg-palmers-mush-grey/20 disabled:border-palmers-dragon-fruit/65 disabled:text-opacity-50"
                error-class="text-[#404041]"
            />
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-3">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="Email Address"
                name="email"
                :disabled="useAccountInfo"
                v-model="authnet.responseData.contactDetails.email"
                :validate="checkout.failed"
                :validator="string().required().email()"
                :validator-attributes="['authnet.contactDetails.email']"
                input-class="disabled:bg-palmers-mush-grey/20 disabled:border-palmers-dragon-fruit/65 disabled:text-opacity-50"
                error-class="text-[#404041]"
            />
        </div>
        <div class="grid grid-cols-1 gap-2">
            <template v-if="!useAccountInfo">
                <InputGroup
                    class="w-full text-black"
                    label-class="text-palmers-charcoal-grey"
                    :type="PhoneInput"
                    label="Phone Number"
                    name="phone"
                    v-model="authnet.responseData.contactDetails.phone"
                    :validate="checkout.failed"
                    :validator="string().required()"
                    :validator-attributes="['authnet.contactDetails.phone']"
                    error-class="text-[#404041]"
                />
            </template>
            <template v-else>
                <InputGroup
                    class="w-full text-black"
                    label-class="text-palmers-charcoal-grey"
                    :type="CheckoutSelect"
                    :options="userPhoneOptions"
                    placeholder="Select Phone Number"
                    label="Phone Number"
                    name="phone"
                    @update="(e) => setPhone(e)"
                    :value="phoneSelectValue"
                    :validate="checkout.failed"
                    :validator-attributes="['authnet.contactDetails.phone']"
                    error-class="text-[#404041]"
                    custom-class="!px-6 !py-3 !text-base !tracking-widest"
                />
            </template>
        </div>
    </div>
</template>
