<script setup>
import { computed } from 'vue';

import {
    useDeliveryAddressStore,
    useCheckoutStore,
    useAuthNetStore,
    useLang,
    useValidatorStore,
} from '@/customer/Composables';

import {
    InputGroup,
    InputError,
    CheckoutSelect,
    CheckoutSelectAddress,
    TextareaInput,
} from '@/customer/Components';
import AddressMethodRadio from '@/customer/Components/Checkout/AddressMethodRadio.vue';

const { lang } = useLang();

const $validator = useValidatorStore();

const $checkout = useCheckoutStore();

const $address = useDeliveryAddressStore();
$address.init(useAuthNetStore());

const deliveryAddressErrors = computed(() => {
    const errorObj = Object.fromEntries(
        Object.entries($validator.errors).filter(([key]) => key.indexOf('deliveryAddress') !== -1),
    );

    if (Object.keys(errorObj).length === 0) {
        return null;
    }

    return errorObj?.deliveryAddress?.address;
});
</script>
<template>
    <div class="text-xl font-bold text-palmers-charcoal-grey">Delivery Information</div>

    <div class="mb-3 flex flex-wrap justify-start gap-x-0 gap-y-3 sm:gap-x-2 md:gap-x-6">
        <AddressMethodRadio
            label="Choose Existing Delivery Address"
            @click="() => $address.setNewAddressFlag(false)"
            :value="false"
            :selected="!$address.newAddressFlag"
        />
        <AddressMethodRadio
            label="Add New Delivery Address"
            @click="() => $address.setNewAddressFlag(true)"
            :value="true"
            :selected="$address.newAddressFlag"
        />
    </div>

    <div v-if="$address.newAddressFlag" class="grid grid-cols-2 gap-x-4 gap-y-3">
        <InputGroup
            v-for="el in $address.getFormElements"
            :id="el.name"
            :name="`deliveryAddress.${el.name}`"
            :label="lang[el.name]"
            :type="el.type"
            v-model="$address.addressForm[el.name]"
            :class="el.class"
            :input-class="el.inputClass"
            :error-message="deliveryAddressErrors?.[el.name]"
            :validator-attributes="[`$address.addressForm.${el.name}`]"
            :validate="$checkout.failed"
            :validator="el.validator"
            v-bind="{ ...(el?.props ?? null) }"
        />
    </div>

    <template v-else>
        <InputGroup
            :type="CheckoutSelectAddress"
            id="addresses"
            :options="$address.getDeliveryAddressLabels"
            placeholder="Choose Address"
            name="deliveryAddress"
            @update:modelValue="
                (selectAddressObject) => $address.selectInputIngress(selectAddressObject)
            "
            :model-value="$address.addressForm.id"
        />

        <InputGroup
            id="notes"
            v-if="$address.addressForm.id !== 0"
            v-model="$address.addressForm.notes"
            :label="lang.notes"
            name="deliveryAddress.notes"
            :error-message="deliveryAddressErrors?.notes"
            :type="TextareaInput"
            class="mt-1 block w-full text-palmers-black"
            rows="4"
            autocomplete="notes"
        />

        <InputError :message="deliveryAddressErrors ? 'Please Select a Valid Address' : null" />
    </template>
</template>
