import { defineStore } from 'pinia';
import mitt from 'mitt';
import asyncDebounce, { str_camel, str_studly } from '@/common/Utils';
import { router } from '@inertiajs/vue3';
import {
    useAuthNetStore,
    useValidatorStore,
    useCartStore,
    useDeliveryAddressStore,
} from '@/customer/Composables';

export default defineStore('checkoutStore', {
    state: () => ({
        failed: false,
        processing: false,
        bus: mitt(),
        debounce: {},
        form: null,
        paymentProfileId: null,
        utensils: false,
        nameForOrder: '',
    }),
    getters: {},
    actions: {
        on(eventName, eventHandler) {
            this.bus.on(eventName, eventHandler);
        },
        beginProcessing(eventName, deps = []) {
            this.processing = true;
            this.failed = false;
            this.bus.emit('beginProcessing', eventName, deps);
        },
        endProcessing(eventName, deps = [], ignoreProcessing = false) {
            this.processing = ignoreProcessing;
            this.bus.emit('endProcessing', eventName, deps);
        },
        async hook(eventName, handler, deps = []) {
            this.bus.emit(`before${str_studly(eventName)}`, ...deps);
            await new Promise((resolve, reject) => {
                let resolvedData = null;
                let error = false;
                console.log({ handler });
                handler
                    .then((...args) => {
                        resolvedData = args;
                        this.bus.emit(`${str_camel(eventName)}`, ...resolvedData);
                    })
                    .catch((...args) => {
                        error = true;
                        resolvedData = args;
                        this.failed = true;
                        console.error(resolvedData);
                        useValidatorStore().setError('checkout', 'checkout-failed');
                        this.bus.emit(`${str_camel(eventName)}Error`, ...resolvedData);
                    })
                    .finally((...args) => {
                        this.bus.emit(`${str_camel(eventName)}Finally`);
                        if (error) {
                            resolve(resolvedData);
                        } else {
                            resolve(resolvedData);
                        }
                    });
            });
        },
        async debounce(id, debounce, args) {
            this.debounce[id] ??= asyncDebounce(this[id], debounce);
            return await this.debounce[id](...args);
        },
        async process(eventName, handler, deps = [], ignoreProcessing = false) {
            this.beginProcessing(eventName, deps);
            await this.hook(eventName, handler, deps);
            this.endProcessing(eventName, deps, ignoreProcessing);
        },
        async subprocess(eventName, handler, deps = []) {
            return this.process(eventName, handler, deps, true);
        },
        async initialize() {
            useAuthNetStore().initialize();
            console.log('init checkout');
            this.on('beforeGateway', (response) => {
                useValidatorStore().clear();
            });
            this.on('gatewayError', (response) => {
                response?.messages?.message?.forEach(({ code, text }) => {
                    setTimeout(() => {
                        useValidatorStore().setError('acceptjs', text);
                        useValidatorStore().setError(code, text);
                    }, 100);
                });
            });
        },

        preparePaymentMethod() {
            const store = useAuthNetStore();
            if (!store.responseData.paymentProfileId) {
                return store.submit('cardData');
            } else {
                return Promise.resolve();
            }
        },

        async submit() {
            const isValid = await useValidatorStore().validateAll();
            this.failed = !isValid;
            if (isValid) {
                await this.process(
                    'checkout',
                    this.subprocess('gateway', this.preparePaymentMethod()).then(() =>
                        axios
                            .post(route('customer.checkout.create'), {
                                authnet: useAuthNetStore().responseData,
                                cart: {
                                    total: useCartStore().summary.total.raw,
                                    uuid: useCartStore().contents.uuid,
                                },
                                deliveryAddress: {
                                    address: useDeliveryAddressStore().getParsedAddressForm,
                                },
                                utensils: this.utensils, 
                                nameForOrder: this.nameForOrder,
                            })
                            .then((response) => {
                                if (response?.data?.redirect) {
                                    router.get(
                                        response.data.redirect,
                                        {},
                                        {
                                            preserveScroll: false,
                                            preserveState: true,
                                        },
                                    );
                                }
                                console.log({ response });
                            })
                            .catch((error) => {
                                useValidatorStore().setErrors(error?.response?.data?.errors);
                                console.log({ response: error.response });
                                this.failed = true;
                            }),
                    ),
                );
            }
        },
        async submitCatering() {
            const isValid = await useValidatorStore().validateAll();
            this.failed = !isValid;
            if (isValid) {
                await axios
                    .post(route('customer.checkout.create.catering'), {
                        cart: {
                            total: useCartStore().summary.total.raw,
                            uuid: useCartStore().contents.uuid,
                        },
                        deliveryAddress: {
                            address: useDeliveryAddressStore().getParsedAddressForm,
                        },
                    })
                    .then((response) => {
                        if (response?.data?.redirect) {
                            router.get(
                                response.data.redirect,
                                {},
                                {
                                    preserveScroll: false,
                                    preserveState: true,
                                },
                            );
                        }
                        console.log({ response });
                    })
                    .catch((error) => {
                        useValidatorStore().setErrors(error?.response?.data?.errors);
                        console.log({ response: error.response });
                    });
            }
        },
    },
});
